import { useStaticQuery, graphql } from 'gatsby'
import dedupe from 'dedupe'

export const useBlogAuthors = () => {
  const { allAuthor } = useStaticQuery(authorsQuery)
  return allAuthor.nodes
    ? dedupe(allAuthor.nodes, node => node.slug)
    : null
}

const authorsQuery = graphql`
  query allAuthorQuery {
    allAuthor {
      nodes {
        ...Author
      }
    }
  }
`
